import React from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, Button } from '@material-ui/core'

import { currentLanguages } from '../i18n/config/currentLanguages'
import Layout from '../components/layout'
import SEO from '../components/seo'
import AllInputProps from '../utils/inputProps'

import CollabRockets from '../assets/svg/collabRockets.svg'
import Headquarters from '../assets/svg/headquarters.svg'

function SecondPage() {
    const { t, i18n } = useTranslation('contact')
    const currentLangPath = currentLanguages.find(lang => lang.shorthand === i18n.language).path

    return (
        <Layout>
            <SEO title="contact:title" />
            <h1 className="pageTitle">{t('title')}</h1>
            <div className="cardGrid">
                <div className="card hereForYouCard">
                    <h3 className="cardTitle">{t('hereForYou')}</h3>
                    <p className="cardParagraph">{t('letUsKnow')}</p>
                    <Button
                        size="large"
                        variant="contained"
                        disableElevation
                        href={`mailto:${t('contactEmail')}`}
                        classes={{ root: 'emailButtonRoot', contained: 'emailButtonContained' }}
                    >
                        {t('contactEmail')}
                    </Button>
                    <CollabRockets />
                </div>
                <div className="card coordinatesCard">
                    <h3 className="cardTitle">{t('coordinates')}</h3>
                    <address className="cardParagraph">
                        {t('addressLine1')}
                        <br />
                        {t('addressLine2')}
                        <br />
                        {t('addressLine3')}
                    </address>
                    <Headquarters />
                </div>
            </div>
            <div className="card contactFormCard">
                <h3 className="cardTitle">{t('contactFormCardTitle')}</h3>
                <form
                    name="Contact"
                    method="post"
                    className="contactForm"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    action={`${currentLangPath}/succes`}
                >
                    {/* Need to have this hidden input with the form name to your JSX form */}
                    <input type="hidden" name="form-name" value="Contact" />
                    <div className="inputGrid">
                        <TextField
                            required
                            fullWidth
                            name="First Name"
                            id="contactForm-firstName"
                            label={t('firstName')}
                            variant="outlined"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...AllInputProps}
                        />
                        <TextField
                            required
                            fullWidth
                            name="LastName"
                            id="contactForm-lastName"
                            label={t('lastName')}
                            variant="outlined"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...AllInputProps}
                        />
                    </div>
                    <TextField
                        required
                        fullWidth
                        name="Email"
                        type="email"
                        id="contactForm-email"
                        label={t('misc:email')}
                        variant="outlined"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...AllInputProps}
                    />
                    <div className="inputGrid">
                        <TextField
                            fullWidth
                            name="Phone"
                            type="tel"
                            id="contactForm-phone"
                            label={t('phone')}
                            variant="outlined"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...AllInputProps}
                        />
                        <TextField
                            fullWidth
                            name="Ext"
                            type="tel"
                            id="contactForm-extension"
                            label={t('ext')}
                            variant="outlined"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...AllInputProps}
                        />
                    </div>

                    <TextField
                        fullWidth
                        name="Enterprise"
                        id="contactForm-enterprise"
                        label={t('enterprise')}
                        variant="outlined"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...AllInputProps}
                    />
                    <TextField
                        required
                        fullWidth
                        multiline
                        rows={3}
                        name="Inquiry"
                        id="contactForm-inquiry"
                        label={t('howCanWeHelp')}
                        variant="outlined"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...AllInputProps}
                    />
                    <Button
                        type="submit"
                        size="large"
                        variant="contained"
                        disableElevation
                        classes={{ root: 'sendButtonRoot', contained: 'sendButtonContained' }}
                    >
                        {t('misc:send')}
                    </Button>
                </form>
            </div>
        </Layout>
    )
}

export default SecondPage
